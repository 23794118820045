/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Filter from '../Filter';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Filters({ checkboxs }) {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  function FiltersMap() {
    return checkboxs.map(({ id, title, options }) => (
      <Col key={id} lg={12} className="filters-item">
        <Filter title={title} options={options} />
      </Col>
    ));
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="filters" fluid>
      <Row className="filters-items">
        <FiltersMap />
      </Row>
    </Container>
  );
}

Filters.propTypes = {
  checkboxs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number, PropTypes.string)),
    ),
  ).isRequired,
};

Filters.defaultProps = {};

export default Filters;
