/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import axios from 'axios';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Assets
import star from '../../../images/shared-images/star.png';
import star1 from '../../../images/shared-images/start1.png';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { baseUploads, FavoriteProfilesEndPoint } from '../../../shared/APIs';
import { getToken } from '../../../shared/Services/auth';

// Style
import './index.scss';
import Topics from '../Topics';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Card({
  id,
  name,
  location,
  speciality,
  skills,
  buttonText,
  ImageUrl,
  favoriteId,
  updateFavoriteList,
}) {
  /* ********************************** HOOKS ********************************* */
  const [isFavorite, setIsFavorite] = useState(!!favoriteId);
  const [currentFavoriteId, setCurrentFavoriteId] = useState(favoriteId);

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  async function addToFavorites() {
    await axios
      .post(
        FavoriteProfilesEndPoint(),
        {
          profileId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      )
      .then(({ data }) => {
        setCurrentFavoriteId(data.data.id);
        setIsFavorite(true);
      });
  }

  async function removeFromFavorites() {
    await axios
      .delete(`${FavoriteProfilesEndPoint()}${currentFavoriteId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(() => {
        setCurrentFavoriteId(undefined);
        setIsFavorite(false);
      });
  }

  function toggleFavorite() {
    if (!isFavorite) {
      addToFavorites();
    } else {
      removeFromFavorites();
    }
  }

  useEffect(() => {
    if (!isFavorite) {
      updateFavoriteList((trigger) => !trigger);
    }
  }, [isFavorite, updateFavoriteList]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="card box-shadow">
      <span>
        <button
          type="button"
          onClick={() => toggleFavorite()}
          className="card__star"
        >
          {isFavorite ? (
            <img src={star} alt="star" />
          ) : (
            <img src={star1} alt="star" />
          )}
        </button>
      </span>
      <div className="card__img">
        <img src={`${baseUploads}${ImageUrl}`} alt="Profile" />
      </div>
      <p className="card__name">{name}</p>
      <p className="card__location">{`Centre ${location?.name}`}</p>
      <p className="card__speciality">{speciality}</p>

      <Topics topics={skills} />
      <div className="card__button-container">
        <button
          type="button"
          className="card__button"
          onClick={() => navigate(`/profile-details/${name}`)}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  speciality: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  buttonText: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  ImageUrl: PropTypes.string.isRequired,
  favoriteId: PropTypes.number,
  updateFavoriteList: PropTypes.func,
};

Card.defaultProps = {
  favoriteId: undefined,
  updateFavoriteList: () => {},
};

export default Card;
