/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Skills({ skills }) {
  /* ******************************** CONSTANTS ******************************* */

  const showFirstElements = 4;
  const showStep = 3;

  /* ********************************** HOOKS ********************************* */

  const [displayIndex, setDisplayIndex] = useState(showFirstElements);

  const hiddenElements = skills.length - displayIndex;
  const restOfElementsToShow = hiddenElements > 3 ? 3 : hiddenElements;

  /* ******************************** CALLBACKS ******************************* */
  function handleClick() {
    setDisplayIndex((currentIndex) => currentIndex + showStep);
  }
  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="skills">
      {skills.map((skill, index) => {
        if (!skill?.trim().length > 0) return null;
        if (index < displayIndex) {
          let animate = false;
          if (index >= displayIndex - showStep) {
            animate = true;
          }
          if (displayIndex <= showFirstElements) {
            animate = true;
          }
          return (
            <span key={skill} className={`skill ${animate ? 'fade-in' : ''}`}>
              {skill}
            </span>
          );
        }
        return <></>;
      })}
      {displayIndex < skills.length && (
        <button type="button" className="skills__more" onClick={handleClick}>
          {`+${restOfElementsToShow}`}
        </button>
      )}
    </div>
  );
}

Skills.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.number, PropTypes.string),
  ).isRequired,
};

Skills.defaultProps = {};

export default Skills;
