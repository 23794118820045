/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import Skills from '../Skills';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Topics({ topics }) {
  /* ******************************** CONSTANTS ******************************* */
  /* ********************************** HOOKS ********************************* */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  /* ******************************** RENDERING ******************************* */
  return (
    <div className="skills-wrapper">
      {topics.map(({ id, skill, topic }) => {
        return (
          <div className="skills-container" key={id}>
            {topic !== 'none' && (
              <span className="skills-topic">{`${topic} :`}</span>
            )}
            <Skills skills={skill} />
          </div>
        );
      })}
    </div>
  );
}

Topics.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.number, PropTypes.string),
  ).isRequired,
};

Topics.defaultProps = {};

export default Topics;
