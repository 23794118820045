/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CustomCheckbox({ id, text, count }) {
  /* ********************************** HOOKS ********************************* */

  const [isChecked, setIsChecked] = useState(false);

  /* ******************************** RENDERING ******************************* */

  return (
    <button
      type="button"
      className="custom-checkbox"
      onClick={() => setIsChecked(!isChecked)}
    >
      <span
        role="checkbox"
        aria-checked={isChecked}
        className={`custom-checkbox__box ${isChecked ? 'checked' : ''}`}
        aria-labelledby={`custom-checkbox-id-${id}`}
      />
      <p id={`custom-checkbox-id-${id}`} className="custom-checkbox__text">
        {text}
      </p>
      <span className="custom-checkbox__count">{count}</span>
    </button>
  );
}

CustomCheckbox.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

CustomCheckbox.defaultProps = {};

export default CustomCheckbox;
