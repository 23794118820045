/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here
import CustomCheckbox from '../../../shared/UIKit/CustomCheckbox';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Filter({ title, options }) {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  function OptionsMap() {
    return options.map(({ id, text, count }) => (
      <div key={id} className="filter-option">
        <CustomCheckbox id={id} text={text} count={count} />
      </div>
    ));
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <>
      <p className="filter__title">{title}</p>
      <div className="filter-options">
        <OptionsMap />
      </div>
    </>
  );
}

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.number, PropTypes.string, PropTypes.number),
  ).isRequired,
};

Filter.defaultProps = {};

export default Filter;
