/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SearchIcon from '../../../images/profiles/search-icon.svg';
import WorkIcon from '../../../images/profiles/work-icon.svg';
import LocationIcon from '../../../images/profiles/location-icon.svg';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import Filters from '../Filters';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SearchForm({
  inputs,
  button,
  setSearchParams,
  searchParams,
  apiCall,
}) {
  /* ******************************** CONSTANTS ******************************* */
  const icons = [SearchIcon, LocationIcon, WorkIcon];
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */

  const { t } = useTranslation();
  const {
    filters: { checkboxs },
  } = t('profilesContent', { returnObjects: true });
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  function handleSubmit(e) {
    e.preventDefault();
    apiCall();
  }
  function handleChange(evt) {
    const { value, name } = evt.target;

    setSearchParams((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }
  const { speciality, center, search } = searchParams;
  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="search-form" fluid>
      <form onSubmit={handleSubmit}>
        <Row justify="between" className="search-form-wrapper">
          <Col lg={9}>
            <div className="search-form-inputs box-shadow">
              <div className="search-form-input-container">
                <img
                  src={icons[0]}
                  alt=""
                  aria-hidden="true"
                  className="search-form__input-icon"
                />
                <label htmlFor="search-form__input-">
                  {inputs[0].placeholder}
                </label>
                <input
                  id="search-form__input-"
                  type="text"
                  placeholder={inputs[0].placeholder}
                  className="search-form__input"
                  name={inputs[0].name}
                  onChange={handleChange}
                  defaultValue=""
                  value={search}
                />
              </div>
              <div className="search-form-input-container">
                <img
                  src={icons[1]}
                  alt=""
                  aria-hidden="true"
                  className="search-form__input-icon"
                />
                <label htmlFor="search-form__input-">
                  {inputs[1].placeholder}
                </label>
                <input
                  id="search-form__input-"
                  type="text"
                  placeholder={inputs[1].placeholder}
                  className="search-form__input"
                  name={inputs[1].name}
                  onChange={handleChange}
                  defaultValue=""
                  value={center}
                />
              </div>
              <div className="search-form-input-container">
                <img
                  src={icons[2]}
                  alt=""
                  aria-hidden="true"
                  className="search-form__input-icon"
                />
                <label htmlFor="search-form__input-">
                  {inputs[1].placeholder}
                </label>
                <input
                  id="search-form__input-"
                  type="text"
                  placeholder={inputs[2].placeholder}
                  className="search-form__input"
                  name={inputs[2].name}
                  onChange={handleChange}
                  defaultValue=""
                  value={speciality}
                />
              </div>
            </div>
          </Col>
          <Col
            lg={3}
            md={12}
            sm={12}
            xs={12}
            className="search-form-button-container"
            align="center"
            justify="center"
          >
            <button type="submit" className="search-form__button box-shadow">
              {button}
            </button>
          </Col>
          <div className="filter">
            {checkboxs ? null : <Filters checkboxs={checkboxs} />}
          </div>
        </Row>
      </form>
    </Container>
  );
}

SearchForm.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.number, PropTypes.string),
  ).isRequired,
  button: PropTypes.string.isRequired,
  setSearchParams: PropTypes.func.isRequired,
  searchParams: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  apiCall: PropTypes.func.isRequired,
};

SearchForm.defaultProps = {};

export default SearchForm;
