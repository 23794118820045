/* eslint-disable mdx/no-unused-expressions */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// Lib UI components
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import SearchForm from '../page-components/Profiles/SearchForm';
// import Filters from '../page-components/Profiles/Filters';
import Cards from '../page-components/Profiles/Cards';
// helpers
import { ProfilesEndPoint } from '../shared/APIs';
import { getToken } from '../shared/Services/auth';
import Loader from '../shared/UIKit/Loader';
// Style
import '../page-styles/Profiles.scss';
// assets
import iconE from '../images/shared-images/iconE.svg';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Profiles() {
  /* ********************************** HOOKS ********************************* */
  // state
  const [profilesList, setProfilesList] = React.useState([]);
  const [searchParams, setSearchParams] = React.useState({
    search: '',
    center: '',
    speciality: '',
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const locale = 'fr';

  // const { profile, center, speciality } = searchParams;
  // Localization
  const { t } = useTranslation();
  const {
    title,
    filters: { inputs, button },
  } = t('profilesContent', { returnObjects: true });

  // Effect
  const getAllProfiles = async () => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: { ...searchParams },
    };

    const { data } = await axios.get(ProfilesEndPoint(locale), config);
    setProfilesList(data.data);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getAllProfiles();
  }, []);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Profiles" />
      <Container className="profiles" fluid>
        <h1 className="profiles__title">{title}</h1>
        <img src={iconE} alt="" className="profiles-img" />
        <Container className="profiles-container">
          <Row>
            <Col lg={12} className="profiles-hero">
              <SearchForm
                inputs={inputs}
                button={button}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
                apiCall={getAllProfiles}
              />
            </Col>
            <Col xxl={12} xl={12} md={12} sm={12} className="profilecard">
              {isLoading ? <Loader /> : <Cards profiles={profilesList} />}
            </Col>
          </Row>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Profiles", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Profiles;
