/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here
import Card from '../Card';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Cards({ profiles, updateFavoriteList }) {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */

  const destructureProfileSkills = (profilesSkills) => {
    return profilesSkills.map((ps) => {
      let { skill } = ps;

      if (!skill.includes(':')) {
        skill = `none:${skill}`;
      }

      let [topic = '', skills = ''] = skill.split(':');

      if (skills.split('/')?.length > 1) {
        return { ...ps, topic, skill: skills.split('/') };
      }

      if (skills.split(',')?.length > 1) {
        return { ...ps, topic, skill: skills.split(',') };
      }

      if (!Array.isArray(skills)) {
        skills = [skills];
      }

      return { ...ps, topic, skill: skills };
    });
  };
  const restructreProfileSkills = (profilesSkills) => {
    const destructuredSkills = destructureProfileSkills(profilesSkills);
    // Merge skills
    const restructredProfileSkills = destructuredSkills.reduce((acc, s) => {
      const existingSkillIndex = acc.findIndex(
        (prvSkill) => prvSkill.topic === s.topic,
      );

      if (existingSkillIndex >= 0) {
        const skills = acc[existingSkillIndex].skill;
        const mergedSkills = [...acc];
        mergedSkills[existingSkillIndex].skill = [...skills, ...s.skill];
        return mergedSkills;
      }
      return [...acc, s];
    }, []);
    return restructredProfileSkills;
  };

  function CardsMap() {
    return profiles?.map(
      ({
        id,
        attributes: {
          jobTitle,
          profileName,
          profileSkills,
          score,
          center,
          profileImage,
          favoriteId,
        },
      }) => {
        return (
          <Col
            key={id}
            xxl={4}
            xl={4}
            lg={4}
            md={12}
            sm={12}
            className="cards-item"
          >
            <Card
              id={id}
              favoriteId={favoriteId}
              score={score}
              name={profileName}
              speciality={jobTitle}
              skills={restructreProfileSkills(profileSkills)}
              location={center}
              ImageUrl={
                profileImage?.data?.attributes?.url ?? `${profileImage?.url}`
              }
              buttonText={t('CardProfileButton')}
              updateFavoriteList={updateFavoriteList}
            />
          </Col>
        );
      },
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="cards" fluid>
      <Row
        className="cards-items"
        align="stretch"
        style={{ display: 'flex', flex: 1 }}
      >
        <CardsMap />
      </Row>
    </Container>
  );
}

Cards.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  updateFavoriteList: PropTypes.func,
};

Cards.defaultProps = {
  updateFavoriteList: () => {},
};

export default Cards;
